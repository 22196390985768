import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
export const pageTitle = "Production checklist";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Warning = makeShortcode("Warning");
const Tip = makeShortcode("Tip");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "production-checklist",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#production-checklist",
        "aria-label": "production checklist permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Production checklist`}</h1>
    <Warning mdxType="Warning">
      <p>{`Note that the advice on this page is not always applicable for every use case and thus should be
applied with caution. Do not apply the changes you really do not need.`}</p>
    </Warning>
    <p>{`You may want to consider the following options before putting your Armeria application into production.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Specify the maximum number of accepted connections. The default is `}<em parentName="p">{`unbounded`}</em>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`import com.linecorp.armeria.server.Server;
import com.linecorp.armeria.server.ServerBuilder;

ServerBuilder sb = Server.builder();
sb.maxNumConnections(500);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Specify an alternative `}<inlineCode parentName="p">{`blockingTaskExecutor`}</inlineCode>{` based on expected workload if your server has
a service that uses it, such as `}<a parentName="p" {...{
            "href": "type://TomcatService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/tomcat/TomcatService.html"
          }}>{`type://TomcatService`}</a>{`, `}<a parentName="p" {...{
            "href": "type://JettyService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/jetty/JettyService.html"
          }}>{`type://JettyService`}</a>{` and `}<a parentName="p" {...{
            "href": "type://THttpService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/thrift/THttpService.html"
          }}>{`type://THttpService`}</a>{` with
synchronous service implementation. The default is a simple `}<inlineCode parentName="p">{`ScheduledThreadPoolExecutor`}</inlineCode>{` with maximum
200 threads, provided by `}<a parentName="p" {...{
            "href": "type://CommonPools:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/common/CommonPools.html"
          }}>{`type://CommonPools`}</a>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`import com.linecorp.armeria.server.ServerBuilder;

ServerBuilder sb = Server.builder();
sb.blockingTaskExecutor(myScheduledExecutorService);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Specify the default limits of an HTTP request or response.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`import java.time.Duration;
import com.linecorp.armeria.client.ClientBuilder;
import com.linecorp.armeria.server.ServerBuilder;

// Server-side
ServerBuilder sb = Server.builder();
sb.maxRequestLength(1048576); // bytes (default: 10 MiB)
sb.requestTimeout(Duration.ofSeconds(7)); // (default: 10 seconds)

// Client-side
ClientBuilder cb = Clients.builder(...); // or WebClient.builder(...)
cb.maxResponseLength(1048576); // bytes (default: 10 MiB)
cb.responseTimeout(Duration.ofSeconds(10)); // (default: 15 seconds)
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Decorate your services with `}<a parentName="p" {...{
            "href": "type://ThrottlingService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/throttling/ThrottlingService.html"
          }}>{`type://ThrottlingService`}</a>{` which lets you fail the incoming requests based on a
policy, such as 'fail if the rate of requests exceed a certain threshold.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`import com.linecorp.armeria.server.throttling.ThrottlingService;
import com.linecorp.armeria.server.throttling.ThrottlingStrategy;

ServerBuilder sb = Server.builder();
sb.service("/my_service", // Allow up to 1000 requests/sec.
           myService.decorate(ThrottlingService.newDecorator(
                   ThrottlingStrategy.rateLimiting(1000.0))));
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Decorate your clients with `}<a parentName="p" {...{
            "href": "type://RetryingClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryingClient.html"
          }}>{`type://RetryingClient`}</a>{`. See `}<a parentName="p" {...{
            "href": "/docs/client-retry#automatic-retry"
          }}>{`Automatic retry`}</a>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Decorate your clients with `}<a parentName="p" {...{
            "href": "type://CircuitBreakerClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreakerClient.html"
          }}>{`type://CircuitBreakerClient`}</a>{`. See `}<a parentName="p" {...{
            "href": "/docs/client-circuit-breaker"
          }}>{`Circuit breaker`}</a>{`.`}</p>
      </li>
    </ul>
    <Tip mdxType="Tip">
      <p>{`You can use Armeria's `}<a parentName="p" {...{
          "href": "type://CircuitBreaker:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/circuitbreaker/CircuitBreaker.html"
        }}>{`type://CircuitBreaker`}</a>{` API for non-Armeria clients without circuit breaker support.
See `}<a parentName="p" {...{
          "href": "/docs/client-circuit-breaker#using-circuitbreaker-with-non-armeria-client"
        }}>{`Using CircuitBreaker with non-Armeria client`}</a>{`.`}</p>
    </Tip>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Tune the socket options.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`import com.linecorp.armeria.client.ClientBuilder;
import com.linecorp.armeria.client.ClientFactory;
import com.linecorp.armeria.client.ClientFactoryBuilder;
import com.linecorp.armeria.server.ServerBuilder;
import io.netty.channel.ChannelOption;

// Server-side
ServerBuilder sb = Server.builder();
sb.channelOption(ChannelOption.SO_BACKLOG, ...);
sb.channelOption(ChannelOption.SO_REUSEADDR, ...);
sb.childChannelOption(ChannelOption.SO_SNDBUF, ...);
sb.childChannelOption(ChannelOption.SO_RCVBUF, ...);

// Client-side
ClientFactoryBuilder cfb = ClientFactory.builder();
cfb.channelOption(ChannelOption.SO_REUSEADDR, ...);
cfb.channelOption(ChannelOption.SO_SNDBUF, ...);
cfb.channelOption(ChannelOption.SO_RCVBUF, ...);
ClientFactory cf = cfb.build();
ClientBuilder cb = Clients.builder(...);
cb.factory(cf);
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Consider increasing `}<a parentName="p" {...{
            "href": "type://ClientFactoryBuilder#maxNumEventLoopsPerEndpoint(int):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryBuilder.html#maxNumEventLoopsPerEndpoint(int)"
          }}>{`type://ClientFactoryBuilder#maxNumEventLoopsPerEndpoint(int)`}</a>{`
and `}<a parentName="p" {...{
            "href": "type://ClientFactoryBuilder#maxNumEventLoopsPerHttp1Endpoint(int):https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactoryBuilder.html#maxNumEventLoopsPerHttp1Endpoint(int)"
          }}>{`type://ClientFactoryBuilder#maxNumEventLoopsPerHttp1Endpoint(int)`}</a>{` when a
client needs to send a large number of requests to a specific endpoint. The client
will assign more CPU resources and create more connections by increasing the number
of event loops up to the quantity in `}<a parentName="p" {...{
            "href": "type://ClientFactory#eventLoopGroup():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/ClientFactory.html#eventLoopGroup()"
          }}>{`type://ClientFactory#eventLoopGroup()`}</a>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java"
          }}>{`import com.linecorp.armeria.client.Clients;
import com.linecorp.armeria.client.ClientBuilder;
import com.linecorp.armeria.client.ClientFactory;
import com.linecorp.armeria.client.ClientFactoryBuilder;

ClientFactoryBuilder cfb = ClientFactory.builder();
cfb.maxNumEventLoopsPerEndpoint(16); // default: 1
cfb.maxNumEventLoopsPerHttp1Endpoint(16); // default: 1
ClientFactory cf = cfb.build();
ClientBuilder cb = Clients.builder(...);
cb.factory(cf);
`}</code></pre>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      